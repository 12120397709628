<script setup>
const props = defineProps({
  sections: {
    type: Array,
    required: true,
  },
});

const components = {
  Sidebar: resolveComponent('LazyCtrSidebar'),
  Text: resolveComponent('LazyCtrText'),
  Head: resolveComponent('LazyCtrHead'),
  ListIconBlocks: resolveComponent('LazyCtrListIconBlocks'),
  Tabs: resolveComponent('LazyCtrTabs'),
  Table: resolveComponent('LazyCtrTable'),
  Title: resolveComponent('LazyCtrTitle'),
  ListChart: resolveComponent('LazyCtrListChart'),
  Documents: resolveComponent('LazyCtrDocuments'),
  Faq: resolveComponent('LazyCtrFaq'),

  IndexHead: resolveComponent('LazyCtrIndexHead'),
  IndexServices: resolveComponent('LazyCtrIndexServices'),
  IndexSbp: resolveComponent('LazyCtrIndexSbpMir'),
  IndexMir: resolveComponent('LazyCtrIndexSbpMir'),
  IndexSecurity: resolveComponent('LazyCtrIndexSecurity'),

  AboutHead: resolveComponent('LazyCtrAboutHead'),
  AboutMission: resolveComponent('LazyCtrAboutMission'),
  AboutFiles: resolveComponent('LazyCtrAboutFiles'),

  AnalyticsHead: resolveComponent('LazyCtrAnalyticsHead'),
  AnalyticsFormat: resolveComponent('LazyCtrAnalyticsFormat'),

  AnalyticsSlider: resolveComponent('LazyCtrAnalyticsSlider'),

  CertificateHead: resolveComponent('LazyCtrCertificateHead'),

  CheckHead: resolveComponent('LazyCtrCheckHead'),
  CheckPartners: resolveComponent('LazyCtrCheckPartners'),
  CheckAdvantages: resolveComponent('LazyCtrCheckAdvantages'),
  CheckDetail: resolveComponent('LazyCtrCheckDetail'),

  KnowledgeHead: resolveComponent('LazyCtrKnowledgeHead'),
  KnowledgeNearest: resolveComponent('LazyCtrKnowledgeNearest'),
  KnowledgeContent: resolveComponent('LazyCtrKnowledgeContent'),

  PressContent: resolveComponent('LazyCtrPressContent'),

  SecurityTabs: resolveComponent('LazyCtrSecurityTabs'),

  VacanciesHead: resolveComponent('LazyCtrVacanciesReferralHead'),
  VacanciesContent: resolveComponent('LazyCtrVacanciesContent'),

  ReferralHead: resolveComponent('LazyCtrVacanciesReferralHead'),
  ReferralContent: resolveComponent('LazyCtrReferralContent'),

  ConferenceHead: resolveComponent('LazyCtrConferenceHead'),
  ConferenceAbout: resolveComponent('LazyCtrConferenceAbout'),
  ConferenceProgram: resolveComponent('LazyCtrConferenceProgram'),
  ConferenceRegistration: resolveComponent('LazyCtrConferenceRegistration'),
  ConferenceContacts: resolveComponent('LazyCtrConferenceContacts'),

  RazvitiyeHead: resolveComponent('LazyCtrRazvitiyeHead'),
  RListIconBlocks: resolveComponent('LazyCtrRListIconBlocks'),
  ListNumberedImages: resolveComponent('LazyCtrListNumberedImages'),
  EsgSlider: resolveComponent('LazyCtrEsgSlider'),
  ProjectsContent: resolveComponent('LazyCtrProjectsContent'),
  Image: resolveComponent('LazyCtrImage'),
};

const sectionsFiltered = computed(() =>
  filterAdaptedSections(props.sections, components, 'BundlesConstructor.vue'),
);
</script>

<template>
  <div class="bundles-constructor">
    <Component
      :is="components[section.type]"
      v-for="(section, sectionIndex) in sectionsFiltered"
      :id="section.id"
      :key="sectionIndex"
      class="bundles-constructor__section"
      v-bind="section.data"
    />
  </div>
</template>
