const adapters = {
  Sidebar: 'sidebar',
  Text: 'text',
  Head: 'head',
  ListIconBlocks: 'list-icon-blocks',
  Tabs: 'tabs',
  Table: 'table',
  Title: 'title',
  ListChart: 'list-chart',
  Documents: 'documents',
  Faq: 'faq',

  IndexHead: 'index-head',
  IndexServices: 'index-services',
  IndexSbp: 'index-sbp-mir',
  IndexMir: 'index-sbp-mir',
  IndexSecurity: 'index-security',

  AboutHead: 'about-head',
  AboutMission: 'about-mission',
  AboutFiles: 'about-files',

  AnalyticsHead: 'analytics-head',
  AnalyticsFormat: 'analytics-format',

  AnalyticsSlider: 'analytics-slider',

  CertificateHead: 'certificate-head',

  CheckHead: 'check-head',
  CheckPartners: 'check-partners',
  CheckAdvantages: 'check-advantages',
  CheckDetail: 'check-detail',

  KnowledgeHead: 'knowledge-head',
  KnowledgeNearest: false,
  KnowledgeContent: false,

  PressContent: 'press-content',

  SecurityTabs: 'security-tabs',

  VacanciesHead: 'vacancies-referral-head',
  VacanciesContent: false,

  ReferralHead: 'vacancies-referral-head',
  ReferralContent: false,

  ConferenceHead: 'conference-head',
  ConferenceAbout: 'conference-about',
  ConferenceProgram: 'conference-program',
  ConferenceRegistration: 'conference-registration',
  ConferenceContacts: 'conference-contacts',

  RazvitiyeHead: 'razvitiye-head',
  RListIconBlocks: 'r-list-icon-blocks',
  ListNumberedImages: 'list-numbered-images',
  EsgSlider: 'esg-slider',
  Image: 'image',
  ProjectsContent: false,
};

const pageMetaAdapter = (data) => {
  return {
    title: data.title,
    description: data.description,
    sharingImage: data.sharing_image,
    url: data.url,
  };
};

export const pageAdapter = async (data) => {
  return {
    sections: await getSectionAdapters(data.blocks, adapters),
    meta: pageMetaAdapter(data),
  };
};
